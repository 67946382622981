var render = function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "col-lg-8 col-md-8 col-12 mt-4 mt-sm-0 pt-2 pt-sm-0"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('router-link', {
    staticClass: "btn btn-primary",
    attrs: {
      "to": {
        name: 'carian.semak'
      }
    }
  }, [_vm._v(_vm._s(_vm.$t("back")))])], 1)]), _c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-lg-12 col-12 mb-4 pb-2"
  }, [_c('div', {
    staticClass: "card bg-light job-box rounded shadow border-0 overflow-hidden"
  }, [_c('div', {
    staticClass: "card-body"
  }, [_c('h5', {
    staticClass: "text-center"
  }, [_vm._v(_vm._s(_vm.$t("print-last-will")))]), _c('hr', {
    staticClass: "primary"
  }), _c('div', {
    staticClass: "row d-flex justify-content-center"
  }, [_c('div', [_c('b-button', {
    staticClass: "btn btn-primary",
    on: {
      "click": _vm.printWasiatCertificate
    }
  }, [_vm._v(" " + _vm._s(_vm.$t("printwill")) + " "), _vm.cetak ? _c('span', {
    staticClass: "spinner-border spinner-border-sm"
  }) : _vm._e()])], 1)])])])])])]);
}
var staticRenderFns = []

export { render, staticRenderFns }